import type { InfoItem } from '@core/api'

const getIsModules = (query: URLSearchParams, branch: InfoItem | null) => {
  if (!branch) return false

  const qs = query.get('delivery_type')

  switch (qs) {
    case 'address':
      return branch.modules.address_delivery
    case 'takeaway':
      return branch.modules.takeaway_delivery
    case 'table':
      return branch.modules.table_delivery
    default:
      return false
  }
}

export const getIsSubModules = (
  query: URLSearchParams,
  branch: InfoItem | null,
  type: 'product_based' | 'min_order_based',
) => {
  if (!branch) return false

  if (type === 'product_based') {
    return _getSubModulesProductBased(query, branch)
  }

  if (type === 'min_order_based') {
    return _getSubModulesMinOrderBased(query, branch)
  }

  return false
}

const _getSubModulesProductBased = (query: URLSearchParams, branch: InfoItem) => {
  const qs = query.get('delivery_type')

  switch (qs) {
    case 'address':
      return branch.modules.submodule_address_delivery_product_based
    case 'takeaway':
      return branch.modules.submodule_takeaway_delivery_product_based
    default:
      return false
  }
}

const _getSubModulesMinOrderBased = (query: URLSearchParams, branch: InfoItem) => {
  const qs = query.get('delivery_type')

  switch (qs) {
    case 'address':
      return branch.modules.submodule_address_delivery_min_order_based
    case 'takeaway':
      return branch.modules.submodule_takeaway_delivery_min_order_based
    default:
      return false
  }
}

export default {
  getIsModules,
  getIsSubModules,
}
