import { type TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore, type Reducer } from '@reduxjs/toolkit'
import baseApi from '@core/api'
import { RTKQueryMiddlewares } from '@core/middlewares'
import { BaseSlice } from '@core/slices'
import { CartSlice } from '@cart/slice'
import type { AppState } from './Store.types'

const combinedReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  baseSlice: BaseSlice,
  cartSlice: CartSlice,
})

const rootReducer: Reducer<AppState> = (state, action) => {
  return combinedReducer(action.type === 'RESET_STATE' ? undefined : state, action)
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(RTKQueryMiddlewares.handleError, baseApi.middleware),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
