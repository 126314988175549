import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { get } from 'lodash'
import { Badge, Icon } from '@components'
import type { ProductHeaderProps } from './ProductHeader.types'
import { EventType, type ReturnType } from './ProductHeader.types'
import { sendPostMessage } from '@core/utils'
import { EVENT_MAP } from './ProductHeader.constants'

const ProductHeader: React.FC<ProductHeaderProps> = ({ logo, title }) => {
  const location = useLocation()

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const handleSendPostMessage = React.useCallback(() => {
    const returnType = query.get('return_type') as ReturnType

    const event = get(EVENT_MAP, returnType, EventType.ADDRESS_CHANGED)

    sendPostMessage(JSON.stringify({ event }))
  }, [query])

  return (
    <div className="animate-enter flex items-center gap-3 bg-semantic-content-brand px-4 py-6">
      <figure>
        <img src={logo} alt={title} className="h-12 w-12 rounded-full object-contain" />
      </figure>
      <div className="">
        <h2 className="text-display-md-b text-semantic-background-inkInverse">{title}</h2>
        <Badge variant="ink-inverse-tonal" size="tiny" onClick={handleSendPostMessage}>
          <Icon name="map-pin" className="mr-1" size={12} />
          {query.get('address')}
          <Icon name="caret-right" className="ml-1" size={12} />
        </Badge>
      </div>
    </div>
  )
}
export default ProductHeader
