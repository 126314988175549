import * as React from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { Icon } from '@components'
import type { ToastProps, ToastStaticProperties } from './Toast.types'
import { useNotification } from '@core/hooks'
import { cn } from '@core/utils'
import { toastStyles } from './Toast.styles'
import ToastAction from './ToastAction'
import ToastProgress from './ToastProgress'

const TOAST_REMOVE_DELAY = 100

const ToastBase = React.forwardRef<HTMLDivElement, ToastProps>(
  ({ id, open = 'fade', duration, variant, className, icon, message, exit, LayoutRightComponent }, ref) => {
    const { dismiss } = useNotification()

    const [isExiting, setIsExiting] = React.useState<boolean>(false)
    const [isRemoved, setIsRemoved] = React.useState<boolean>(false)

    React.useEffect(() => {
      if (open && duration) {
        const timer = setTimeout(() => {
          dismiss(id)
        }, duration)
        return () => clearTimeout(timer)
      }
    }, [dismiss, duration, id, open])

    React.useEffect(() => {
      if (exit && duration) {
        const exitTimer = setTimeout(() => setIsExiting(true), duration)
        const removeTimer = setTimeout(() => setIsRemoved(true), duration + TOAST_REMOVE_DELAY)

        return () => {
          clearTimeout(exitTimer)
          clearTimeout(removeTimer)
        }
      }
    }, [duration, exit])

    React.useEffect(() => {
      if (!exit) {
        setIsExiting(false)
        setIsRemoved(false)
      }
    }, [exit])

    if (isRemoved) return null

    return (
      <div ref={ref} data-exiting={isExiting} data-state={open} className={cn(toastStyles({ variant, className }))}>
        <div className="flex items-center gap-2">
          {icon ? <Icon name={icon} size={24} /> : null}
          <span className="text-body-sm-r leading-none">{message}</span>
        </div>
        {LayoutRightComponent ? (
          <div>{typeof LayoutRightComponent === 'function' ? LayoutRightComponent(variant) : LayoutRightComponent}</div>
        ) : null}
      </div>
    )
  },
)

const Toast: React.FC<ToastProps> & ToastStaticProperties = props => {
  return <ToastBase {...props}>{props.children}</ToastBase>
}

Toast.displayName = ToastPrimitives.Root.displayName

Toast.Action = ToastAction
Toast.Progress = ToastProgress

export default Toast
