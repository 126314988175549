import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, ToolBar } from '@components'
import { useAppSelector } from '@core/store'
import i18n from '@core/translations/Translator.ts'
import type { ProductsFooterProps } from './Products.types'
import { calculateCartTotalPrice, Modules } from '@core/utils'

const ProductsFooter: React.FC<ProductsFooterProps> = ({ cart, branchSlug }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const branch = useAppSelector(state => state.baseSlice.info)

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const isModules = React.useMemo(() => {
    return Modules.getIsModules(query, branch)
  }, [branch, query])

  const cartTotalPrice = React.useMemo(() => {
    if (!cart.length || !branch) return 0

    return calculateCartTotalPrice(cart, branch, { locale: i18n.language, currency: branch.currency }, query)
  }, [branch, cart, query])

  if (!isModules || !cart.length) return null

  return (
    <ToolBar className="fixed bottom-0 z-50 w-full">
      <ToolBar.Left className="flex flex-col gap-[2px]">
        <span className="text-body-md-r text-semantic-content-inkMedium">
          {t('cart.product_count_label', { products_count: cart.length })}
        </span>
        <strong className="text-body-lg-b text-semantic-content-ink">{cartTotalPrice}</strong>
      </ToolBar.Left>
      <ToolBar.Right className="w-full">
        <Button className="w-full" onClick={() => navigate(`/${branchSlug}/cart?${query}`)}>
          {t('product.go_to_cart')}
        </Button>
      </ToolBar.Right>
    </ToolBar>
  )
}
export default ProductsFooter
