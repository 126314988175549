import * as React from 'react'
import type { ProgressCircleProps } from './Progress.types'
import { useCircleProgress } from './Progress.hooks'
import { cn, useTheme } from '@core/utils'

const ProgressCircle = React.forwardRef<SVGSVGElement, ProgressCircleProps>((props, ref) => {
  const { colors } = useTheme()
  const {
    value,
    valueTextColor,
    indicatorColor,
    valueTextStyle,
    backgroundColor = colors.semantic.background.quaternary,
    strokeLinecap = 'round',
    size,
    totalValue,
    children,
    className,
  } = props

  const { c, r, sw, sd } = useCircleProgress(size)

  const percentageValue = React.useMemo(() => {
    return (value / totalValue) * 100
  }, [totalValue, value])

  return (
    <div className={cn('progress-circle relative', className)}>
      <div className="relative" style={{ width: size, height: size }}>
        <svg ref={ref} height={size} width={size}>
          <circle
            cx={c}
            cy={c}
            r={r}
            fill={colors.solid.core.transparent}
            stroke={backgroundColor ?? colors.solid.brand[5]}
            strokeWidth={sw}
            strokeDasharray={sd}
            strokeDashoffset={0}
            style={{
              transition: 'transform 0.5s ease-out, opacity 0.5s ease-out',
            }}
          />
          <circle
            cx={c}
            cy={c}
            r={r}
            fill={colors.solid.core.transparent}
            stroke={colors.solid.core.white}
            strokeWidth={sw - size * 0.001}
            strokeLinecap={strokeLinecap}
            strokeDasharray={sd}
            transform={`rotate(-90 ${c} ${c})`}
            strokeDashoffset={sd * ((100 - percentageValue) * 0.01)}
          />
          <circle
            cx={c}
            cy={c}
            r={r}
            fill={colors.solid.core.transparent}
            stroke={indicatorColor ?? colors.solid.ink[9]}
            strokeWidth={sw - size * 0.025}
            strokeLinecap={strokeLinecap}
            strokeDasharray={sd}
            strokeDashoffset={sd * ((100 - percentageValue) * 0.01)}
            transform={`rotate(-90 ${c} ${c})`}
          />
        </svg>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {!children && (
            <div className="text-body-lg-b text-semantic-content-disabled" style={{ color: valueTextColor }}>
              {`${value}/${totalValue}`}
            </div>
          )}
        </div>
      </div>
      {children && (
        <div className="flex text-body-lg-b text-semantic-content-ink" style={{ ...valueTextStyle }}>
          {children}
        </div>
      )}
    </div>
  )
})

ProgressCircle.displayName = 'ProgressCircle'

export default ProgressCircle
