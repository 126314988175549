import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { lt } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import { Toast } from '@components'
import { useAppSelector } from '@core/store'
import i18n from '@core/translations'
import { calculateCartTotalPrice, Modules } from '@core/utils'

const MinOrderAmountInfo: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const branch = useAppSelector(state => state.baseSlice.info)
  const cart = useAppSelector(state => state.cartSlice.products)

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const isModules = React.useMemo(() => {
    return Modules.getIsModules(query, branch)
  }, [branch, query])

  const isSubModules = React.useMemo(() => {
    return Modules.getIsSubModules(query, branch, 'min_order_based')
  }, [branch, query])

  const minAmount = React.useMemo(() => {
    if (!branch) return null

    return branch.settings.min_order_amount.amount
  }, [branch])

  const cartTotal = React.useMemo(() => {
    return +calculateCartTotalPrice(cart, branch, { returnType: 'default' }, query)
  }, [branch, cart, query])

  const formattedLimit = React.useMemo(() => {
    if (!branch || !minAmount) return null

    return CurrencyFormatter.format(minAmount, i18n.language, branch.currency)
  }, [branch, minAmount])

  const formattedRemainingPrice = React.useMemo(() => {
    if (!branch || !minAmount) return null

    return CurrencyFormatter.format(minAmount - cartTotal, i18n.language, branch.currency)
  }, [branch, cartTotal, minAmount])

  const toastMessage = React.useMemo(() => {
    if (!minAmount) return null

    if (lt(cartTotal, minAmount))
      return t('order_limit.not_completed', {
        limit: formattedLimit,
        remainingPrice: formattedRemainingPrice,
      })

    return t('order_limit.completed')
  }, [cartTotal, formattedLimit, formattedRemainingPrice, minAmount, t])

  const LayoutRightComponent = React.useMemo(() => {
    if (!minAmount) return null

    if (lt(cartTotal, minAmount))
      return <Toast.Progress type="default" value={cartTotal} totalValue={minAmount} children=" " />

    return undefined
  }, [cartTotal, minAmount])

  if (!cart.length || !minAmount || !isModules || !isSubModules) return null

  return (
    <div className="fixed bottom-[105px] left-3 right-3 z-50">
      <Toast
        message={toastMessage as string}
        LayoutRightComponent={LayoutRightComponent as JSX.Element}
        variant={lt(cartTotal, minAmount) ? 'default' : 'success'}
        duration={3000}
        exit={!lt(cartTotal, minAmount)}
      />
    </div>
  )
}
export default MinOrderAmountInfo
