import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { debounce, find, get, omit } from 'lodash'
import { BottomSheet, Button, IconButton, ToolBar } from '@components'
import { useAppDispatch, useAppSelector } from '@core/store'
import { setAddToCart } from '@cart/slice'
import type { ProductDetailFooterProps } from './ProductDetail.types'
import { getMaxQuantity, getProductByVariationId, getProductQuantity } from './ProductDetail.utils'

const ProductDetailFooter: React.FC<ProductDetailFooterProps> = ({
  product,
  selectedVariationId,
  quantity,
  isModules,
  isValidBasket,
  setQuantity,
  setOpen,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const cart = useAppSelector(state => state.cartSlice.products)

  const [loading, setLoading] = React.useState<boolean>(false)

  const isIncreaseValid = React.useMemo(() => {
    const foundProduct = getProductByVariationId(cart, selectedVariationId)
    const foundMaxQuantity = getMaxQuantity(foundProduct)
    const foundProductQuantity = getProductQuantity(foundProduct)

    if (foundProduct) return foundMaxQuantity - foundProductQuantity !== quantity

    return get(find(product.variations, { id: selectedVariationId }), 'max_quantity') !== quantity
  }, [cart, quantity, product.variations, selectedVariationId])

  const isCartValid = React.useMemo(() => {
    const foundProduct = getProductByVariationId(cart, selectedVariationId)
    const foundProductQuantity = getProductQuantity(foundProduct)

    return get(foundProduct, 'variation.max_quantity') !== foundProductQuantity
  }, [cart, selectedVariationId])

  const handleQuantity = React.useCallback(
    (action: 'increase' | 'decrease') => () => {
      if (action === 'decrease') return setQuantity(quantity - 1)

      return setQuantity(quantity + 1)
    },
    [quantity, setQuantity],
  )

  const handleAddToBasket = React.useCallback(() => {
    if (!product) return
    setLoading(true)

    const productWithoutVariations = omit(product, ['variations'])

    debounce(() => {
      dispatch(
        setAddToCart({
          ...productWithoutVariations,
          quantity,
          variation: find(product.variations, { id: selectedVariationId }),
        }),
      )
      setOpen(null)
    }, 750)()
  }, [dispatch, product, quantity, selectedVariationId, setOpen])

  React.useEffect(() => {
    setQuantity(1)
  }, [selectedVariationId, setQuantity])

  if (!isModules || !isValidBasket) return null

  return (
    <BottomSheet.Footer>
      <ToolBar>
        <ToolBar.Left className="flex items-center gap-3">
          <IconButton name="minus" size="small" disabled={quantity <= 1} onClick={handleQuantity('decrease')} />
          <strong>{quantity}</strong>
          <IconButton
            name="plus"
            size="small"
            disabled={!isIncreaseValid || !isCartValid}
            onClick={handleQuantity('increase')}
          />
        </ToolBar.Left>
        <ToolBar.Right className="w-full">
          <Button
            className="w-full"
            loading={loading}
            disabled={!selectedVariationId || !isCartValid}
            onClick={handleAddToBasket}>
            {t('cart.add_to_cart')}
          </Button>
        </ToolBar.Right>
      </ToolBar>
    </BottomSheet.Footer>
  )
}
export default ProductDetailFooter
