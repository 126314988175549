import { find, get, isNumber, some } from 'lodash'
import type { InfoItem } from '@core/api'
import type { ProductCartType } from '@cart/slice'
import type { ProductDetailVariationsItem } from '@products/api'
import type { DeliveryTypeProps } from '@products/views/Products/Products.types'
import { Modules } from '@core/utils'

export const getIsValidPrice = (
  query: URLSearchParams,
  variations: ProductDetailVariationsItem[],
  selectedVariationId: number | null,
) => {
  const checkPrice = (variation: ProductDetailVariationsItem) => {
    const price = getDeliveryVariationsPrice(query, null, variation)

    return isNumber(price)
  }

  if (selectedVariationId !== null) {
    const selectedVariation = find(variations, { id: selectedVariationId })
    return selectedVariation ? checkPrice(selectedVariation) : false
  }

  return some(variations, checkPrice)
}

export const getDeliveryVariationsPrice = (
  query: URLSearchParams,
  branch: InfoItem | null,
  variation: ProductDetailVariationsItem,
) => {
  const deliveryQuery = query.get('delivery_type') as DeliveryTypeProps

  if (!Modules.getIsSubModules(query, branch, 'product_based')) return variation.price ?? ''

  switch (deliveryQuery) {
    case 'table':
      return variation.price ?? ''
    case 'address':
      return variation.address_delivery_price ?? ''
    case 'takeaway':
      return variation.takeaway_delivery_price ?? ''
    default:
      return variation.price ?? ''
  }
}

export const getVariationsPrice = (
  query: URLSearchParams,
  branch: InfoItem | null,
  variations: ProductDetailVariationsItem[],
  selectedVariationId: number | null,
) => {
  if (selectedVariationId)
    return getDeliveryVariationsPrice(
      query,
      branch,
      find(variations, { id: selectedVariationId }) as ProductDetailVariationsItem,
    )

  return variations.find(variation => getDeliveryVariationsPrice(query, branch, variation))?.price ?? 0
}

export const getProductByVariationId = (cart: ProductCartType[], variationId: number | null) => {
  return find(cart, product => product.variation.id === variationId)
}

export const getMaxQuantity = (product?: ProductCartType) => {
  return get(product, 'variation.max_quantity', 0)
}

export const getProductQuantity = (product?: ProductCartType) => {
  return get(product, 'quantity', 0)
}
