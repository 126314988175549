import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '@core/store'
import productsApi from '@products/api'
import type { ProductDetailProps } from './ProductDetail.types'
import { getIsValidPrice } from './ProductDetail.utils'
import { Modules } from '@core/utils'
import ProductDetailContent from './ProductDetailContent'
import ProductDetailFooter from './ProductDetailFooter'
import ProductDetailImage from './ProductDetailImage'

const ProductDetail: React.FC<ProductDetailProps> = ({ menuSlug, categorySlug, productSlug, setOpen }) => {
  const location = useLocation()

  const [selectedVariationId, setSelectedVariationId] = React.useState<number | null>(null)
  const [quantity, setQuantity] = React.useState<number>(1)

  const branch = useAppSelector(state => state.baseSlice.info)

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const ProductDetailQuery = productsApi.useProductDetailQuery(
    {
      menuSlug,
      categorySlug,
      productSlug,
    },
    { refetchOnMountOrArgChange: true },
  )

  const product = React.useMemo(() => ProductDetailQuery.data?.data, [ProductDetailQuery])

  const isModules = React.useMemo(() => {
    return Modules.getIsModules(query, branch)
  }, [branch, query])

  const isValidPrice = React.useMemo(() => {
    if (!product) return false

    return getIsValidPrice(query, product.variations, selectedVariationId)
  }, [product, query, selectedVariationId])

  if (!product) return null

  return (
    <React.Fragment>
      <div className="mt-2 w-full overflow-y-auto border-t border-semantic-background-tertiary pb-10">
        <ProductDetailImage image={product.detail_image_url} title={product.name} />
        <div className="container flex flex-col gap-10">
          <ProductDetailContent
            title={product.name}
            description={product.description}
            allergies={product.allergies}
            variations={product.variations}
            query={query}
            isValidPrice={isValidPrice}
            selectedVariationId={selectedVariationId}
            setSelectedVariationId={setSelectedVariationId}
          />
        </div>
      </div>
      <ProductDetailFooter
        product={product}
        selectedVariationId={selectedVariationId}
        quantity={quantity}
        isModules={isModules}
        isValidBasket={isValidPrice}
        setQuantity={setQuantity}
        setOpen={setOpen}
      />
    </React.Fragment>
  )
}
export default ProductDetail
