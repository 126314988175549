import * as React from 'react'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge, Image } from '@components'
import i18n from '@core/translations'
import type { ProductCardProps } from './ProductCard.types'
import { cn } from '@core/utils'
import ProductCardButtons from './ProductCardButtons'

const ProductCard: React.FC<ProductCardProps> = ({
  as,
  title,
  description,
  price,
  image,
  isModules,
  orderCount,
  isInCart,
  quantity,
  IconButtonIncreaseProps,
  IconButtonDecreaseProps,
  IconButtonProps,
  className,
  ...props
}) => {
  const Comp = as || 'div'

  const formattedPrice = React.useMemo(() => {
    return CurrencyFormatter.format(price, i18n.getLocale(), 'TRY')
  }, [price])

  return (
    <Comp
      className={cn('animate-enter flex gap-4 rounded-[20px] bg-semantic-background-primary px-4 py-[14px]', className)}
      {...props}>
      <figure className="flex-shrink-0 rounded-[13px] bg-semantic-content-inkInverse">
        <Image src={image} alt={title} className="h-[114px] w-[82px] rounded-[13px] object-cover" />
      </figure>
      <div className="flex flex-grow flex-col justify-between">
        <div className="flex-grow space-y-2 text-left">
          <div className="flex items-center justify-between gap-4">
            <h3 className="line-clamp-1 text-body-md-b text-semantic-content-ink">{title}</h3>
            {orderCount && orderCount > 0 ? (
              <Badge as="span" variant="brand-tonal" size="tiny" className="shrink-0 px-2">
                {i18n.t('cart.product_quantity_label', { product_count: orderCount })}
              </Badge>
            ) : null}
          </div>
          <p className="line-clamp-3 text-body-md-r text-semantic-content-inkMedium">{description}</p>
        </div>
        <div className="flex flex-shrink-0 items-center justify-between">
          <Badge as="span" variant="yellow" size="small" className="px-3">
            {formattedPrice}
          </Badge>
          <ProductCardButtons
            isInCart={isInCart}
            quantity={quantity}
            isModules={isModules}
            IconButtonIncreaseProps={IconButtonIncreaseProps}
            IconButtonDecreaseProps={IconButtonDecreaseProps}
            IconButtonProps={IconButtonProps}
          />
        </div>
      </div>
    </Comp>
  )
}
export default ProductCard
