import type { InfoItem } from '@core/api'
import type { ProductCartType } from '@cart/slice'
import type { ProductItem } from '@products/api'
import type { DeliveryTypeProps } from './Products.types'
import { Modules } from '@core/utils'

export const getDeliveryPrice = (
  query: URLSearchParams,
  branch: InfoItem | null,
  product?: ProductItem,
  cartProduct?: ProductCartType,
): number => {
  const deliveryQuery = query.get('delivery_type') as DeliveryTypeProps

  if (!Modules.getIsSubModules(query, branch, 'product_based')) {
    return product?.price ?? cartProduct?.variation.price ?? 0
  }

  switch (deliveryQuery) {
    case 'table':
      return product?.price ?? cartProduct?.variation.price ?? 0
    case 'address':
      return product?.address_delivery_price ?? cartProduct?.variation.address_delivery_price ?? 0
    case 'takeaway':
      return product?.takeaway_delivery_price ?? cartProduct?.variation.takeaway_delivery_price ?? 0
    default:
      return product?.price ?? cartProduct?.variation.price ?? 0
  }
}

export const getProductsListPadding = (cartLength: number, isMinAmount?: number | null) => {
  return cartLength > 0 ? (isMinAmount ? 186 : 128) : 24
}
