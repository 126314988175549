import * as React from 'react'
import { Progress } from '@components'
import type { ToastProgressProps } from './Toast.types'
import { getToastProgressColors } from './Toast.utils'
import { useTheme } from '@core/utils'

const ToastProgress: React.FC<ToastProgressProps> = ({ type = 'default', ...props }) => {
  const theme = useTheme()

  const colors = React.useMemo(() => {
    return getToastProgressColors(type, theme)
  }, [theme, type])

  return (
    <Progress.Circle
      backgroundColor={colors.background}
      indicatorColor={colors.indicator}
      valueTextColor={colors.text}
      size={40}
      {...props}
    />
  )
}

ToastProgress.displayName = 'Toast.Progress'

export default ToastProgress
