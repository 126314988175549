import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import { Badge, SectionBar } from '@components'
import { useAppSelector } from '@core/store'
import i18n from '@core/translations/Translator.ts'
import type { ProductDetailContentProps } from './ProductDetail.types'
import { getVariationsPrice } from './ProductDetail.utils'
import ProductDetailAllergies from './ProductDetailAllergies'

const ProductDetailContent: React.FC<ProductDetailContentProps> = ({
  title,
  description,
  allergies,
  variations,
  query,
  isValidPrice,
  selectedVariationId,
  setSelectedVariationId,
}) => {
  const { t } = useTranslation()

  const branch = useAppSelector(state => state.baseSlice.info)

  const formattedPrice = React.useMemo(() => {
    if (!branch || !isValidPrice) return null

    return CurrencyFormatter.format(
      getVariationsPrice(query, branch, variations, selectedVariationId),
      i18n.getLocale(),
      branch?.currency,
    )
  }, [branch, isValidPrice, query, selectedVariationId, variations])

  React.useEffect(() => {
    if (!variations.length) return
    setSelectedVariationId(variations[0].id)
  }, [setSelectedVariationId, variations])

  return (
    <React.Fragment>
      <div>
        <SectionBar title={title} description={description} className="py-4" />
        {isValidPrice && (
          <Badge variant="yellow" size="medium" className="px-5">
            {formattedPrice}
          </Badge>
        )}
      </div>

      <div className="space-y-3">
        <strong className="text-body-md-b text-semantic-content-ink">{t('product.variations')}</strong>
        <div className="flex flex-wrap gap-2">
          {map(variations, (variation, index) => (
            <Badge
              key={index}
              variant={variation.id === selectedVariationId ? 'brand' : 'outlined'}
              size="medium"
              className="px-5"
              onClick={() => setSelectedVariationId(variation.id)}>
              {variation.name}
            </Badge>
          ))}
        </div>
      </div>

      <ProductDetailAllergies allergies={allergies} />
    </React.Fragment>
  )
}
export default ProductDetailContent
