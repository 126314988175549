const SetTheme = (color?: string) => {
  if (!color) return

  const themeColor = color

  document.documentElement.style.setProperty('--content-brand', themeColor)
  document.documentElement.style.setProperty('--brand-5', themeColor)
  document.documentElement.style.setProperty('--brand-1', themeColor + '1A')
  document.documentElement.style.setProperty('--brand-tint-5', themeColor + '0d')
  document.documentElement.style.setProperty('--brand-tint-10', themeColor + '1A')
  document.documentElement.style.setProperty('--brand-tint-20', themeColor + '33')
}

export default {
  SetTheme,
}
