import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { lt, map } from 'lodash'
import { Button, ToolBar } from '@components'
import { useAppSelector } from '@core/store'
import i18n from '@core/translations/Translator.ts'
import type { CartFooterProps } from './Cart.types'
import { calculateCartTotalPrice, Modules, sendPostMessage } from '@core/utils'

const CartFooter: React.FC<CartFooterProps> = ({ cart, query }) => {
  const { t } = useTranslation()

  const branch = useAppSelector(state => state.baseSlice.info)

  const isSubModules = React.useMemo(() => {
    return Modules.getIsSubModules(query, branch, 'min_order_based')
  }, [branch, query])

  const minAmount = React.useMemo(() => {
    if (!branch || !isSubModules) return null

    return branch.settings.min_order_amount.amount
  }, [branch, isSubModules])

  const cartTotalPrice = React.useMemo(() => {
    if (!cart.length || !branch) return 0

    return calculateCartTotalPrice(cart, branch, { locale: i18n.language, currency: branch.currency }, query)
  }, [branch, cart, query])

  const handleSendPostMessage = React.useCallback(() => {
    const message = JSON.stringify({
      event: 'CHECKOUT',
      payload: map(cart, product => ({
        variation_id: product.variation.menu_product_variation_id,
        qty: product.quantity,
      })),
    })

    sendPostMessage(message)
  }, [cart])

  if (!cart.length) return null

  return (
    <ToolBar className="fixed bottom-0 z-50 w-full">
      <ToolBar.Left className="flex flex-col gap-[2px]">
        <span className="text-body-md-r text-semantic-content-inkMedium">
          {t('cart.product_count_label', { products_count: cart.length })}
        </span>
        <strong className="text-body-lg-b text-semantic-content-ink">{cartTotalPrice}</strong>
      </ToolBar.Left>
      <ToolBar.Right className="w-full">
        <Button
          className="w-full"
          disabled={lt(calculateCartTotalPrice(cart, branch, { returnType: 'default' }, query), minAmount)}
          onClick={handleSendPostMessage}>
          {t('cart.payment')}
        </Button>
      </ToolBar.Right>
    </ToolBar>
  )
}
export default CartFooter
