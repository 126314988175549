import type * as React from 'react'
import type { ProgressStaticProperties } from './Progress.types'
import ProgressBar from './ProgressBar'
import ProgressCircle from './ProgressCircle'

const Progress: React.FC & ProgressStaticProperties = () => {
  return null
}

Progress.Bar = ProgressBar
Progress.Circle = ProgressCircle

Progress.displayName = 'Progress'

export default Progress
