import * as React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { NotificationProvider } from '@core/hooks'
import MinOrderAmountInfo from './MinOrderAmountInfo'

const Layout: React.FC = () => {
  return (
    <NotificationProvider>
      <Outlet />
      <MinOrderAmountInfo />
      <ScrollRestoration />
    </NotificationProvider>
  )
}
export default Layout
