export interface ProductHeaderProps {
  logo: string
  title: string
}

export enum EventType {
  ADDRESS_CHANGED = 'ADDRESS_CHANGED',
  BRANCH_CHANGED = 'BRANCH_CHANGED',
}

export type ReturnType = 'address' | 'branch'
