export default {
  cart: {
    route_header_title: 'Sepetim',
    title: 'Sepetim',
    description:
      'Alışveriş sepetinizdeki ürünleri burada görebilir, miktarlarını güncelleyebilir veya istemediğiniz ürünleri çıkarabilirsiniz.',
    add_to_cart: 'Sepete Ekle',
    product_count_label: '{{products_count}} ürün',
    product_quantity_label: '{{product_count}} adet',
    remove_product: 'Ürünü Sil',
    remove_product_message: 'Ürünü sepetinizden silmek istediğinize emin misiniz?',
    remove_cancel: 'Vazgeç',
    payment: 'Öde',
    product_not_in_basket: 'Ürün ({{name}}) sepette olmadığı için kaldırılamıyor!',
    clear_cart_title: 'Sepeti Temizle',
    clear_cart_message: 'Sepetteki tüm ürünleri silmek istediğinize emin misiniz?',
    clear_cart_confirm: 'Sepeti Temizle',
    clear_cart_cancel: 'Vazgeç',
  },
  product: {
    route_header_title: 'Menü',
    allergies: 'Alerjenler',
    variations: 'Çeşitler',
    go_to_cart: 'Sepete Git',
  },
  order_limit: {
    not_completed: '{{ limit }}’lik minimum sepet tutarına {{ remainingPrice }} kaldı.',
    completed: 'Minimum sepet tutarını tamamladınız! 🎉',
  },
  not_found: {
    title: 'Tüh! Aradığınız sayfa burada değil',
    description: 'Aradığınız sayfa sitemizden kaldırılmış ya da adresi değiştirilmiş olabilir.',
    error: 'HTTP ERROR 404',
  },
}
