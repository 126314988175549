import { sumBy } from 'lodash'
import { CurrencyFormatter } from '@macellan/formatter'
import type { InfoItem } from '@core/api'
import type { ProductCartType } from '@cart/slice'
import { getDeliveryPrice } from '@products/views/Products/Products.utils.ts'

type DefaultReturnType = {
  returnType: 'default'
  locale?: string
  currency?: string
}

type FormatterReturnType = {
  returnType?: 'formatter'
  locale: string
  currency: string
}

type CalculateCartTotalPriceParams = DefaultReturnType | FormatterReturnType

const calculateCartTotalPrice = (
  cart: ProductCartType[],
  branch: InfoItem | null,
  params: CalculateCartTotalPriceParams,
  query: URLSearchParams,
): string => {
  if (!cart.length) return '0'

  const totalPrice = sumBy(cart, product => getDeliveryPrice(query, branch, undefined, product) * product.quantity)

  if (params.returnType === 'default') return totalPrice.toString()

  return CurrencyFormatter.format(totalPrice, params.locale, params.currency)
}

export default calculateCartTotalPrice
