import { cva } from 'class-variance-authority'

export const toastStyles = cva(
  [
    'group pointer-events-auto duration-500 relative w-full h-[56px] rounded-[20px] px-4 flex justify-between items-center gap-3 overflow-hidden transition-all data-[state=fade]:animate-in data-[state=fade]:zoom-in-95 data-[state=true]:animate-in data-[state=false]:animate-out data-[state=false]:fade-out-80 data-[state=false]:slide-out-to-right-full data-[state=true]:slide-in-from-top-full data-[state=true]:sm:slide-in-from-bottom-full data-[exiting=true]:animate-out data-[exiting=true]:fade-out-80 data-[exiting=true]:slide-out-to-right-full',
  ],
  {
    variants: {
      variant: {
        default: ['bg-solid-ink-8', 'text-semantic-content-inkInverse', 'fill-semantic-content-inkInverse'],
        suggestion: ['!bg-solid-yellow-1', 'text-semantic-content-ink', 'fill-semantic-content-ink'],
        success: ['bg-solid-green-6', 'text-semantic-content-inkInverse', 'fill-semantic-content-inkInverse'],
        error: ['bg-semantic-content-error', 'text-semantic-content-inkInverse', 'fill-semantic-content-inkInverse'],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)
