import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Icon, Navigation, ProductCard, SectionBar } from '@components'
import { useAppDispatch, useAppSelector } from '@core/store'
import type { ProductCartType } from '@cart/slice'
import { setAddToCart, setClearCart, setRemoveFromCart } from '@cart/slice'
import { getDeliveryPrice } from '@products/views/Products/Products.utils.ts'
import { useNotification } from '@core/hooks'
import { Modules } from '@core/utils'
import CartFooter from './CartFooter'

const Cart: React.FC = () => {
  const { t } = useTranslation()
  const { alert } = useNotification()
  const navigate = useNavigate()
  const location = useLocation()

  const dispatch = useAppDispatch()

  const branch = useAppSelector(state => state.baseSlice.info)
  const cart = useAppSelector(state => state.cartSlice.products)

  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search])

  const isModules = React.useMemo(() => {
    return Modules.getIsModules(query, branch)
  }, [branch, query])

  const handleProductQuantity = React.useCallback(
    (product: ProductCartType, action: 'increase' | 'decrease') => () => {
      if (action === 'decrease') {
        if (product.quantity === 1)
          return alert({
            title: t('cart.remove_product'),
            message: t('cart.remove_product_message'),
            actions: [
              {
                children: t('cart.remove_product'),
                onClick: () => dispatch(setRemoveFromCart(product)),
              },
              {
                variant: 'secondary',
                children: t('cart.remove_cancel'),
              },
            ],
          })

        return dispatch(setRemoveFromCart(product))
      }

      dispatch(setAddToCart({ ...product, quantity: 1 }))
    },
    [alert, dispatch, t],
  )

  const handleClearCart = React.useCallback(() => {
    alert({
      title: t('cart.clear_cart_title'),
      message: t('cart.clear_cart_message'),
      actions: [
        {
          children: t('cart.clear_cart_confirm'),
          onClick: () => dispatch(setClearCart()),
        },
        {
          variant: 'secondary',
          children: t('cart.clear_cart_cancel'),
        },
      ],
    })
  }, [alert, dispatch, t])

  React.useEffect(() => {
    if (cart.length === 0) return navigate(-1)
  }, [cart, navigate])

  React.useEffect(() => {
    if (!isModules) return navigate(-1)
  }, [isModules, navigate])

  return (
    <React.Fragment>
      <Navigation
        title={t('cart.route_header_title')}
        LayoutRightComponent={
          <button className="fill-semantic-background-inkInverse" onClick={handleClearCart}>
            <Icon name="delete" size={24} />
          </button>
        }
      />
      <div className="container" style={{ paddingBottom: cart.length > 0 ? 128 : 24 }}>
        <SectionBar title={t('cart.title')} description={t('cart.description')} className="py-5" />
        <div className="space-y-2">
          {map(cart, (product, index) => (
            <ProductCard
              key={index}
              title={product.name}
              description={product.variation.name}
              price={getDeliveryPrice(query, branch, undefined, product) as number}
              image={product.detail_image_url}
              isModules={isModules}
              isInCart={true}
              quantity={product.quantity}
              IconButtonIncreaseProps={{
                onClick: handleProductQuantity(product, 'increase'),
                disabled: product.variation.max_quantity === product.quantity,
              }}
              IconButtonDecreaseProps={{
                name: product.quantity === 1 ? 'delete' : 'minus',
                onClick: handleProductQuantity(product, 'decrease'),
              }}
            />
          ))}
        </div>
      </div>
      <CartFooter cart={cart} query={query} />
    </React.Fragment>
  )
}
export default Cart
